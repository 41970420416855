import { useAgency } from 'context/agency'
import React from 'react'
import { Link } from 'react-router-dom'

function AppLogo() {
  return (
    <Link to={'/'}>
      <img src={'/img/loyalyy.png'} alt='logo' width={'120px'} />
    </Link>
  )
}

export default AppLogo
