import { useAgency } from 'context/agency'
import React from 'react'
import AppLogo from './app-logo'

function Header() {
  const { agency, style } = useAgency()

  return (
    <div class='p-5' style={{ backgroundColor: style.background.top_header }}>
      <div class='flex justify-between items-center'>
        <AppLogo />
        <div style={{ color: style.text.top_header }}>
          {agency?.scan_config?.title || 'Scanner'}
        </div>
      </div>
    </div>
  )
}

export default Header
